<template>
<div class="card card-height-100">
    <div class="card-body">
        <div class="row g-2">
            <div class="col-6"> 
                <span>Claim ID</span>
                <h6> {{item.friendly_id}} </h6>
            </div> 
            <div class="col-6"> 
                <span>Status</span>
                <div><span v-html="statusBadge(item.status)"> </span></div>
            </div>
            <div class="col-6"> 
                <span>Loss type</span>
                <h6> {{item.loss_type.replace(/_/g, ' ')}} </h6>
            </div>
            <div class="col-sm-6"> 
                <span>Loss date</span>
                <h6> {{$filters.date_time(item.loss_date, 1)}} </h6>
            </div>
            <div class="col-sm-6"> 
                <span>Paypal email</span>
                <h6> {{item.paypal_email}} </h6>
            </div>
            <div class="col-sm-6"> 
                <span>Digital signature</span>
                <h6> {{ item.digital_signature}} </h6>
            </div>
            <div v-if="item.item" class="col-12"> 
                <span>Your item</span>
                <h6> <router-link :to="`/items/${item.item.id}`">{{ item.item.title}} </router-link></h6>
            </div>
        </div>
        <hr>
        <slot name="actions"></slot>
    </div>
</div>

</template>

<script>
export default {
    props:{
        item:{
            type:Object,
            required: true
        }
    }, 
}
</script>

<style>

</style>