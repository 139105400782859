
<template>
<div>
  <div class="row mb-4 gy-2">
    <div class="col-12">
      <div class="d-sm-flex mb-4 justify-content-between align-items-center">
        <h5 class="mb-3 mb-sm-0">Your Open Claims</h5>
        <router-link class="btn btn-primary" to="/claims/file-new">
              <i class="ri align-bottom ri-add-fill"></i>  File new claim </router-link>
      </div>
      <div v-if="!isLoading">
        <div v-if="items.length" class="row">
          <div class="col-sm-6" v-for="(item, dIndex) of items" :key="dIndex">
              <claim-card :item="item" @showImg="showImg($event)">
                <template v-slot:actions>
                  <router-link :to="`/claims/${item.id}`" class="btn me-3 btn-primary btn-sm">
                  View claim</router-link>
                </template>
              </claim-card>
          </div>
        </div>
        <div v-else class="fs-5 text-muted text-center">There are no claims associated with your account yet. <br > Click the "File Claim" button above to submit one</div>
      </div>
      <is-loading v-else />
    </div>
  </div>
</div>
</template>

<script>
import ClaimCard from "@/components/cards/ClaimCard.vue"
import IsLoading from '@/components/IsLoading.vue'

export default {
  components:{
    ClaimCard,
    IsLoading
  },
  data(){
    return {
      popupModalShow: false,
      isLoading: true,
    }
  },
  computed:{
    activeUser(){
      return this.$store.state.AppActiveUser
    },
    items(){
      return this.$store.state.claims
    },
  },
  created(){
    this.$store.dispatch("fetchClaims")
    .then((response) => { this.isLoading = false; });
    this.$store.dispatch("fetchItems")
  }
}

</script>

<style lang="scss">

</style>
